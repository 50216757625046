import React, { memo, useContext } from 'react';
import PropTypes from 'prop-types';
import { storyblokEditable } from 'gatsby-source-storyblok';

import { Box, ThemeContext } from 'grommet';
import { Tabs, Tab } from '../Tabs';
import PaintCollectionCard from '../Paint/PaintCollectionCard';
import DynamicGrid from '../DynamicPage/DynamicGrid';

const DynamicPaintRelatedProducts = ({
  blok,
  product,
  relatedCollections,
  similarCollections,
  relatedProducts,
}) => {
  const relatedPaintProducts = relatedProducts;
  const paintColCount =
    relatedPaintProducts.length > 4 ? 4 : relatedPaintProducts.length;
  const theme = useContext(ThemeContext);
  return (
    <Box {...storyblokEditable(blok)}>
      <Tabs>
        <Tab plain title="Similar To">
          <DynamicGrid
            blok={{
              columnCount: paintColCount,
              mobileColumnCount: 2,
              mobileHorizontalScroll: true,
              mobileHorizontalScrollColumnWidth: '60vw',
              rowsFillContainerHeight: true,
              items: similarCollections.map((c) => {
                const matchingProduct = c.products.find(
                  (p) => p.productType === product.productType
                );

                return {
                  reactComponent: (
                    <PaintCollectionCard
                      fill
                      key={c.handle}
                      collection={c}
                      product={matchingProduct}
                      showShortDescription={true}
                      imageContainerProps={{
                        width: { max: '250px' },
                        fill: 'horizontal',
                      }}
                      containerProps={{
                        pad: {
                          horizontal: 'xsmall',
                          vertical: '5vw',
                        },
                      }}
                      badgeContainerProps={{
                        pad: '1vw',
                      }}
                      srcSetSizes={`(max-width: ${theme.global.breakpoints.small.value}px) 20vw, (max-width: ${theme.global.breakpoints.medsmall.value}px) 70vw, (max-width: 1125px) 18vw, 12vw`}
                    />
                  ),
                };
              }),
            }}
          ></DynamicGrid>
        </Tab>
        <Tab plain title="Pair With">
          <DynamicGrid
            blok={{
              columnCount: paintColCount,
              mobileColumnCount: 2,
              mobileHorizontalScroll: true,
              mobileHorizontalScrollColumnWidth: '60vw',
              rowsFillContainerHeight: true,
              items: relatedCollections.map((c) => {
                const matchingProduct = c.products.find(
                  (p) => p.productType === product.productType
                );

                return {
                  reactComponent: (
                    <PaintCollectionCard
                      fill
                      key={c.handle}
                      collection={c}
                      product={matchingProduct}
                      showShortDescription={true}
                      imageContainerProps={{
                        width: { max: '250px' },
                        fill: 'horizontal',
                      }}
                      containerProps={{
                        pad: {
                          horizontal: 'xsmall',
                          vertical: '5vw',
                        },
                      }}
                      badgeContainerProps={{
                        pad: '1vw',
                      }}
                      srcSetSizes={`(max-width: ${theme.global.breakpoints.small.value}px) 100vw, (max-width: ${theme.global.breakpoints.medsmall.value}px) 70vw, (max-width: 1125px) 18vw, 12vw`}
                    />
                  ),
                };
              }),
            }}
          ></DynamicGrid>
        </Tab>
      </Tabs>
    </Box>
  );
};

DynamicPaintRelatedProducts.propTypes = {
  blok: PropTypes.shape({
    limit: PropTypes.number,
  }),
  collection: PropTypes.object,
  product: PropTypes.object,
  relatedProducts: PropTypes.array,
  relatedCollections: PropTypes.array,
  similarCollections: PropTypes.array,
  relatedPaintCollections: PropTypes.array,
  relatedColorProducts: PropTypes.array,
};

export default memo(DynamicPaintRelatedProducts);
